import { useTable, flexRender } from "@pankod/refine-react-table";
import {
    List,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    HStack,
    Text,
    NumberField,
    Select,
    Badge,
    DeleteButton,
} from "@pankod/refine-chakra-ui";

import {
    ColumnFilter,
    ColumnSorter,
    FilterElementProps,
    MainSection,
    Pagination,
    getStatusString,
    useAppModeData,
    useStartNewOrder,
} from "ui-core";
import { useNavigation } from "@pankod/refine-core";

export const OrderList: React.FC = () => {
    const navMethods = useNavigation();
    const appModeData = useAppModeData();
    
    const columns = [
        {
            id: 'customerName',
            header: 'Customer',
            accessorKey: 'customer',
            
            cell: function render({ getValue }: any) {
                let customer = getValue();
                return customer ? `${customer.firstName} ${customer.lastName}` : '';
            },
            meta: {
                filterOperator: 'contains',
            },
            enableSorting: false,
        },
        {
            id: 'total',
            header: 'Total',
            accessorKey: 'total',
            
            cell: function render({ getValue }: any) {
                return (
                    <NumberField options={{ style: 'currency', currency: 'USD' }} value={getValue()/100} />
                );
            },
        },
        {
            id: 'state',
            header: 'Status',
            accessorKey: 'state',
            cell: function render({ getValue }: any) {
                return (
                    getStatusString(getValue() )
                );
            },
            meta: {
                filterElement: function render(props: FilterElementProps) {
                    return (
                        <Select {...props}>
                            <option value="Draft">Draft</option>
                            <option value="PendingApproval">Pending Approval</option>
                            <option value="PendingSignature">Pending Signature</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Approved">Completed</option> 
                        </Select>
                    );
                },
                filterOperator: "eq",
            }
        },
        {
            id: 'createdAt',
            header: 'Date',
            accessorKey: 'createdAt',
            cell: ({ getValue }: any) => {
                const date = new Date(getValue());
                return new Intl.DateTimeFormat(undefined, {
                    dateStyle: 'short',
                    timeStyle: 'short',
                }).format(date);
            },
            enableColumnFilter: false,
        },
    ];

    if (appModeData.mode == 'superAdmin') {
        columns.push({
            id: 'organizationName',
            header: 'Company',
            accessorKey: 'customFields.organization',
            cell: ({ getValue }: any) => {
                return getValue()?.name || '';
            },
            meta: {
                filterOperator: 'contains',
            },
            enableSorting: false,
        });
    }

    const {
        getHeaderGroups,
        getRowModel,
        setOptions,
        refineCore: {
            tableQueryResult: { data: tableData },
            setCurrent,
            pageCount,
            current,
        },
    } = useTable({
        columns,
        refineCoreProps: {
            resource: 'orders',
            metaData: {
                operation: 'findOrders',
            },
            initialSorter: [
                {
                    field: "id",
                    order: "desc",
                    
                },
            ],
            permanentFilter: [
                {
                    operator: "and",
                    value: [
                        {
                            field: "orderType",
                            operator: "null",
                            value: true,
                        },
                    ],
                },
            ],
        },
    });

    

    setOptions((prev) => ({
        ...prev,
        meta: {
            ...prev.meta,
            
        },
    }));

    function doRowNav(id: any) {
        navMethods.show("orders", tableData?.data[id].id as any);
    }

    function getRowButtons(id: any) {
        if(tableData?.data[id].state === "Draft" ) {
            return(
                <Td>
                    <DeleteButton
                        hideText
                        size="small"
                        recordItemId={tableData?.data[id].id as any}
                        
                    />
                </Td>
            );
        } else {
            return(
                <Td></Td>
            )
        }
    }

    const startNewOrder = useStartNewOrder({ navigateOnSucess: true });
    function onCreateClick() {
        startNewOrder.mutate();
    }

    return (
        <MainSection>
        <List 
            createButtonProps={{onClick: () => onCreateClick()}}
        >
            <TableContainer whiteSpace="pre-line">
                <Table variant="simple" colorScheme="gray">
                    <Thead>
                        {getHeaderGroups().map((headerGroup) => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <Th key={header.id}>
                                        {!header.isPlaceholder && (
                                            <HStack spacing="2">
                                                <Text>
                                                    {flexRender(
                                                        header.column.columnDef
                                                            .header,
                                                        header.getContext(),
                                                    )}
                                                </Text>
                                                <HStack spacing="2">
                                                    <ColumnSorter
                                                        column={header.column}
                                                    />
                                                    <ColumnFilter
                                                        column={header.column}
                                                    />
                                                </HStack>
                                            </HStack>
                                        )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </Thead>
                    <Tbody>
                        {getRowModel().rows.map((row) => (
                            <Tr cursor="pointer" key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <Td key={cell.id} onClick={()=> doRowNav(row.id as any)}>
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </Td>
                                ))}
                                {getRowButtons(row.id as any)}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Pagination
                current={current}
                pageCount={pageCount}
                setCurrent={setCurrent}
            />
        </List>
        </MainSection>
       
    );
};